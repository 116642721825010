<template>
  <v-card class="mb-3">
    <v-card-text>
      <v-row align="center">
        <v-col cols="2">
          <v-img
            :src="item.image"
            height="50px"
          />
        </v-col>
        <v-col cols="5">
          <h4>{{ item.productName }}</h4>
          <h5>{{ item.optionName }}</h5>
          <h3>${{ item.price }}</h3>
        </v-col>
        <v-col cols="5">
          <v-row no-gutters align="center">
            <v-col cols="4">
              <v-btn
                color="warning"
                tile
                x-small
                block
                height="39"
                @click="emitDecrement"
              >
                <v-icon class="pa-0 ma-0" color="black">
                  mdi-minus
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="4">
              <!-- <div class="black--text text-center">1</div> -->
              <v-text-field
                v-model="innerQuantity"
                class="quantity"
                dense
                outlined
                hide-details
                style="height:38px"
              />
            </v-col>
            <v-col cols="4">
              <v-btn
                color="warning"
                tile
                x-small
                block
                height="39"
                @click="emitIncrement"
              >
                <v-icon class="pa-0 ma-0" color="black">
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>
            <!-- <v-col cols="12">Quitar</v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    quantity: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      innerQuantity: 1
    }
  },
  watch: {
    // Handles internal model changes.
    innerQuantity (newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    quantity (newVal) {
      this.innerQuantity = newVal;
    }
  },
  created() {
    this.innerQuantity = this.quantity
  },
  methods: {
    emitDecrement() {
      this.$emit('actionDecrement', {id: this.item.productId, optionId: this.item.optionId})
    },
    emitIncrement() {
      this.$emit('actionIncrement', {id: this.item.productId, optionId: this.item.optionId})
    },
  }
}
</script>

<style>
.quantity .v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}
</style>