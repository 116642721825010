<template>
  <v-card>
    <v-card-title>
      <h4>Ticket #{{ ticketNumber }}</h4>
      <v-spacer></v-spacer>
      <h6 class="graydefault--text">{{ today() }}</h6>
    </v-card-title>
    <v-card-subtitle>
      Cliente: {{ customerName }} <br>
      Vendedor: {{ sellerName }}
    </v-card-subtitle>
    <v-card-text>
      <section class="items-max-height overflow-y-auto mr-1">
        <slot name="items"></slot>
      </section>
    </v-card-text>
    <v-card-actions>
      <v-row
        class="px-2"
        no-gutters
      >
        <v-col cols="12">
          <v-divider></v-divider>
          <v-list dense flat>
            <v-list-item class="amount-height">
              <v-list-item-title>Cantidad de productos</v-list-item-title>
              <v-list-item-action>
                <v-list-item-action-text>{{ itemsCount }}</v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>

            <v-list-item class="amount-height">
              <v-list-item-title>Subtotal</v-list-item-title>
              <v-list-item-action>
                <v-list-item-action-text>${{ subtotalTicket.toFixed(2) }}</v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>

            <v-list-item class="amount-height">
              <v-list-item-title>Impuestos</v-list-item-title>
              <v-list-item-action>
                <v-list-item-action-text>${{ amountTaxes.toFixed(2) }}</v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>

            <!-- <v-list-item class="amount-height">
              <v-list-item-title>Descuentos</v-list-item-title>
              <v-list-item-action>
                <v-list-item-action-text>$0.00</v-list-item-action-text>
              </v-list-item-action>
            </v-list-item> -->

            <v-list-item class="amount-height">
              <v-list-item-title>Total</v-list-item-title>
              <v-list-item-action>
                <v-list-item-action-text>
                  <span class="success--text text-body-1">${{ bigTotal.toFixed(2) }}</span>
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12">
          <v-btn
            class="mb-3"
            color="warning"
            tile
            block
            :disabled="!itemsCount"
            @click="emitContinue"
          >
            <span class="black--text">Continuar</span>
          </v-btn>
        </v-col>
        <v-col
          class="pr-1"
          cols="6">
          <v-btn
            color="primary"
            tile
            outlined
            block
            @click="emitSave"
          >
            Guardar
          </v-btn>
        </v-col>
        <v-col
          class="pl-1"
          cols="6">
          <v-btn
            color="error"
            tile
            outlined
            block
            @click="emitCancel"
          >
            Cancelar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    customerName: {
      type: String,
      default: ''
    },
    sellerName: {
      type: String,
      default: ''
    },
    ticketNumber: {
      type: String,
      default: ''
    },
    itemsCount: {
      type: Number,
      default: 0
    },
    subtotalTicket: {
      type: Number,
      default: 0
    },
    amountTaxes: {
      type: Number,
      default: 0
    },
    bigTotal: {
      type: Number,
      default: 0
    }
  },
  methods: {
    emitContinue() {
      this.$emit('actionContinue')
    },
    emitSave() {
      this.$emit('actionSave')
    },
    emitCancel() {
      this.$emit('actionCancel')
    },
    today() {
      const currentDate = new Date()
      return `${currentDate.toLocaleDateString('es-MX')} ${currentDate.toLocaleTimeString('es-MX')}`
    }
  }
}
</script>

<style scoped>
.v-list--dense .v-list-item {
  min-height: 28px;
}
.amount-height {
  height: 28px !important;
}
.items-max-height {
  max-height: 280px;
}
</style>