import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import { apiMixin } from '../../../shared/mixins/apiMixin';
import {
  BASE_CREATE_DEBT,
  BASE_CREATE_INCOME,
  BASE_INVENTORY_MOVEMENTS,
} from '../../helpers/states';
import { fetchGetImage } from '../../../shared/helpers/imagesOptions';
import { fetchPostDebit } from '../../helpers/debitUtils';
import { fetchPostIncome } from '../../helpers/incomeUtils';
import {
  fetchPostMovementInventory,
  fetchPutInventory,
} from '../../helpers/inventoryUtils';
import {
  fetchPostOrder,
  fetchPostOrderDetail,
} from '../../helpers/ordersUtils';
import { getItem } from '../../../../api/api-methods';
import { MOVEMENT_TYPES_ACCOUNT } from '../../../shared/helpers/statesMovementsTypesAccount';
import { saleUtilsMixing } from '../../mixins/saleUtilsMixin';

import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnProductContainer from '../../../shared/components/KnProductContainer.vue';
import KnProductItem from '../../../shared/components/KnProductItem.vue';
import KnSearcher from '../../../shared/components/KnSearcher.vue';
import KnSelect from '../../../shared/components/KnSelect.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import KnTicketContainer from '../../../shared/components/KnTicketContainer.vue';
import KnTicketItem from '../../../shared/components/KnTicketItem.vue';
import { AccountTransactionMixin } from '../../../shared/mixins/AccountTransactionMixin';
import {
  ValuesSalesDebt,
  ValuesSalesIncome,
} from '../../../products/helpers/statesSale';
import { fetchStudentByName } from '../../../students/helpers/utilsStudent';
import { paginationMixin } from '../../../shared/mixins/paginationMixin';

export default {
  components: {
    KnProductContainer,
    KnProductItem,
    KnSearcher,
    KnTicketContainer,
    KnTicketItem,
    KnSelect,
    KnTextField,
    KnLocalAlert,
  },
  mixins: [paginationMixin, apiMixin, saleUtilsMixing, AccountTransactionMixin],
  props: {
    tabValue: {
      type: Number,
      default: 66,
    },
  },
  data() {
    return {
      inventory: [],
      images: [],
      prices: [],
      taxes: [],
      students: [],
      paymentMethods: [],
      bankAccounts: [],
      orderStatus: [],
      shippingTypes: [],
      coins: [],
      customerSelected: null,
      orderStatusId: null,
      ticketOrderNumber: null,
      openSessionDialog: false,
      openPaymentDialog: false,
      sessionExist: true,
      stepSessionSelection: false,
      stepCustomerSelection: false,
      paymentMethodId: null,
      accountId: null,
      mxCoin: null,
      search: null,
      minuend: 0,
      rest: 0,
      loadingStudents: false,
      searchInput: '',

      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /************************ */
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    ...mapGetters(['nameUserLoggedIn']),
    ...mapGetters('pos', [
      'ticketItems',
      'ticketSubTotalPrice',
      'ticketTotalItems',
      'ticketSubTotalTaxes',
      'ticketTotal',
      'isSessionOrderEmpty',
      'customerId',
      'orderNumber',
    ]),
    sessionSelectionTitle() {
      return this.stepSessionSelection
        ? 'Selecciona una sesión'
        : 'Selecciona un cliente';
    },
    // validStudents() {
    //   return this.students.filter(student => student.estatus_sistema === true
    //     && student.datos_personales.primer_nombre !== 'Publico')
    // },
    validOrderStatus() {
      return this.orderStatus.filter(
        (status) => status.estatus_sistema === true
      );
    },
    customerFullName() {
      return this.customerSelected
        ? this.fullName(this.customerSelected.datos_personales)
        : '';
    },
    isCash() {
      if (!Array.isArray(this.paymentMethods) || this.paymentMethodId == null)
        return false;
      return this.paymentMethods.some(
        (pm) =>
          pm.id === this.paymentMethodId && pm.dato.toLowerCase() === 'efectivo'
      );
    },
    searchIsEmpty() {
      return this.search === '' || this.search === null;
    },
    inventoryFiltered() {
      return this.searchIsEmpty
        ? this.inventory
        : this.filterIt(this.inventory, this.search);
    },
    ticketVolumetricWeight() {
      const ticketItems = [...this.ticketItems];
      let volumetricWeight = 0;
      ticketItems.forEach((item) => {
        const inventorySelected = this.inventory.find(
          (inventory) => inventory.producto.id === item.productId
        );
        volumetricWeight =
          volumetricWeight +
          parseFloat(inventorySelected.producto.peso_volumetrico);
      });
      return volumetricWeight;
    },
  },
  watch: {
    isSessionOrderEmpty(val) {
      this.sessionExist = !val;
      if (this.sessionExist) {
        this.showSessionSelection();
      } else {
        this.showCustomerSelection();
      }
    },
    isCash(obj) {
      if (obj) {
        const status = this.orderStatus.find((item) => item.dato === 'Pagada');
        this.orderStatusId = status.id;
      }
    },
  },
  async created() {
    try {
      this.setIsPOS(true);

      const requests = [
        getItem(
          `app-administracion/filters/cuenta?institucion_educativa=${this.institutionId}&estatus_sistema=true`
        ),
        getItem(
          `app-administracion/filters/moneda?institucion_educativa=${this.institutionId}&estatus_sistema=true`
        ),
        getItem(
          `app-inventarios/filters/inventario?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`
        ),
        getItem(
          `app-ordenes/filters/mv-estatus-orden?estatus_sistema=true&limit=20`
        ),
        getItem(
          `app-administracion/filters/forma-de-pago?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=30`
        ),
        getItem(
          `app-productos/filters/precio-distribuidor?estatus_sistema=true`
        ),
        getItem(
          `app-productos/filters/impuesto?institucion_educativa=${this.institutionId}&estatus_sistema=true`
        ),
      ];

      const responses = await Promise.all(requests);

      this.bankAccounts = responses[0].results;
      this.coins = responses[1].results;
      this.inventory = responses[2].results;
      this.orderStatus = responses[3].results;
      this.paymentMethods = responses[4].results;
      this.prices = responses[5].results;
      this.taxes = responses[6].results;

      await this.getStudents();
      await this.populateILastmageProduct();
      this.loadTicketFromStorage();
      this.sessionExist = !this.isSessionOrderEmpty;

      if (this.sessionExist) {
        this.showSessionSelection();
      } else {
        this.showCustomerSelection();
      }
      this.openSessionDialog = true;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },

  beforeRouteLeave(to, from, next) {
    this.setIsPOS(false);
    next();
  },
  methods: {
    ...mapMutations(['setIsPOS']),
    ...mapMutations('pos', [
      'pushProductToTicket',
      'incrementItemQuantity',
      'setSessionOrderCustomer',
      'setSessionOrderNumber',
    ]),
    ...mapActions('pos', [
      'loadTicketFromStorage',
      'cancelSession',
      'addProductToTicket',
      'decrementItemQuantity',
    ]),
    async populateILastmageProduct() {
      for (const inventory of this.inventory) {
        const imageId = inventory.producto.imagenes[0];
        if (!imageId) {
          inventory.producto.imagenes = [];
        } else {
          const image = await fetchGetImage(imageId);
          inventory.producto.imagenes = [];
          inventory.producto.imagenes[0] = image;
        }
      }
    },

    async getStudents() {
      try {
        this.loadingStudents = true;
        let count;
        if (this.searchInput) {
          const { data } = await fetchStudentByName(
            this.searchInput,
            this.pagination,
            true,
            this.institutionId
          );
          count = data.count;
          this.students = data.results;
        } else {
          const limitFilter =
            this.pagination.limit !== null
              ? `&limit=${this.pagination.limit}`
              : '';
          const offsetFilter =
            this.pagination.offset !== 0
              ? `&offset=${this.pagination.offset}`
              : '';
          const url = `/app-personas/filters/alumno?institucion_educativa=${this.institutionId}&autor=${this.userData.id}&estatus_sistema=true${offsetFilter}${limitFilter}`;
          const responseData = await getItem(url);
          count = responseData.count;
          this.students = responseData.results;
        }
        this.setPaginationCount(count);
      } catch (error) {
        console.error('Error fetching students:', error);
      } finally {
        this.loadingStudents = false;
      }
    },
    async searchStudents() {
      await this.delay(500);
      await this.getStudents();
    },

    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    addProduct(item) {
      const inventorySelected = this.inventory.find(
        (inv) =>
          inv.producto.id === item.productId &&
          inv.opcion_producto.id === item.optionId
      );
      const itemToAdd = {
        ...item,
        inventory: { ...inventorySelected },
      };
      // console.log('itemToAdd:', itemToAdd);
      this.addProductToTicket(itemToAdd);
    },
    incrementQuantity(item) {
      this.incrementItemQuantity(item);
    },
    decrementQuantity(item) {
      this.decrementItemQuantity(item);
    },
    showSessionSelection() {
      this.stepSessionSelection = true;
      this.stepCustomerSelection = false;
    },
    showCustomerSelection() {
      this.stepSessionSelection = false;
      this.stepCustomerSelection = true;

      this.cancelSession();
    },
    async setGeneralPublicAsCustomer() {
      const genericStudent = await this.getOrCreatePublicCustomer(
        'Público',
        this.userData.id,
        this.institutionId
      );
      this.customerSelected = genericStudent;

      this.setCustomer();
    },
    setCustomer() {
      this.ticketOrderNumber = this.getOrderNumber();
      this.setSessionOrderCustomer(this.customerSelected.id);
      this.setSessionOrderNumber(this.ticketOrderNumber);
      this.openSessionDialog = false;
    },
    getFullName(student) {
      if (!student || !student.datos_personales) return 'Nombre desconocido';

      const {
        primer_nombre,
        segundo_nombre,
        apellido_paterno,
        apellido_materno,
      } = student.datos_personales;
      const firstName = primer_nombre || '';
      const secondName = segundo_nombre || '';
      const lastName1 = apellido_paterno || '';
      const lastName2 = apellido_materno || '';
      return `${firstName} ${secondName} ${lastName1} ${lastName2}`.trim();
    },

    setCustomerFromLocalStorage() {
      const customer = this.students.find(
        (student) => student.id === this.customerId
      );
      this.customerSelected = customer;
      this.openSessionDialog = false;
    },
    saveToLocalStorage() {
      localStorage.setItem(
        'customerId',
        JSON.stringify(this.customerSelected.id)
      );
      localStorage.setItem('ticket', JSON.stringify(this.ticketItems));
      localStorage.setItem('orderNumber', JSON.stringify(this.orderNumber));
    },
    clearLocalStorage() {
      localStorage.removeItem('customerId');
      localStorage.removeItem('ticket');
      localStorage.removeItem('orderNumber');
    },
    cancelSessionOrder() {
      this.cancelSession();
      this.customerSelected = null;
      this.openSessionDialog = true;
    },
    goTo(routeName) {
      this.$router.push({ name: routeName });
    },
    fullName({
      primer_nombre: pn,
      segundo_nombre: sn,
      apellido_paterno: ap,
      apellido_materno: am,
    }) {
      return [pn, sn, ap, am]
        .filter((val) => val !== null && val !== undefined)
        .join(' ');
    },
    getOrderNumber() {
      const now = new Date();
      const timeInMili = now.getTime();
      const timeString = timeInMili.toString();
      return timeString.substring(8, timeString.length);
    },
    async createOrder() {
      try {
        this.loading = true;
        this.alertText = 'Cargando...';
        this.showAlert = true;

        this.openPaymentDialog = false;

        const responsibleTutor = this.customerSelected.tutores.find(
          (tutor) => tutor.responsable_economico === true
        );
        const tutorTaxId = responsibleTutor.datos_fiscales.slice(0, 1).shift();

        const shippingType = await this.getOrCreateShippingTypes(
          'mostrador',
          this.userData.id
        );

        const customerAddressId =
          this.customerSelected.datos_personales.direccion;

        this.mxCoin = this.coins.find((coin) => coin.codigo_iso === 'MXN');

        const orderStatusSelected = this.orderStatus.find(
          (oStatus) => oStatus.id === this.orderStatusId
        );

        const orderObj = {
          numero_orden: this.orderNumber,
          estatus_orden: this.orderStatusId,
          alumno: this.customerId,
          datos_facturacion: tutorTaxId,
          institucion_educativa: this.institutionId,
          tipo_envio: shippingType.id,
          direccion_envio: customerAddressId,
          peso_volumetrico: this.ticketVolumetricWeight,
          sub_total: this.ticketSubTotalPrice.toFixed(2),
          total_impuestos: this.ticketSubTotalTaxes.toFixed(2),
          costo_envio: 0.0,
          total_descuento: 0.0,
          total_orden: this.ticketTotal.toFixed(2),
          moneda: this.mxCoin.id || 1,
          descuento_aplicado: false,
          autor: this.userData.id,
          orden_completada: false,
        };
        const { res: order } = await fetchPostOrder(orderObj);
        const orderId = order.id;

        if (!orderId) {
          this.errors.push('No se pudo crear la orden');
          return;
        }

        const operationsPromises = this.ticketItems.map(async (item) => {
          const orderDetailPromise = this.createOrderDetail(
            orderId,
            item,
            order
          );
          const updateInventoryPromise = this.updateInventory(item);
          const createInventoryMovementPromise =
            this.createInventoryMovement(item);
          const createAccountMovementPromise = this.createAccountMovement();
          const orderStatus = this.processOrderStatus(orderStatusSelected.dato);
          await Promise.all([
            orderDetailPromise,
            updateInventoryPromise,
            createInventoryMovementPromise,
            createAccountMovementPromise,
            orderStatus,
          ]);
        });

        await Promise.all(operationsPromises);

        this.loading = false;
        this.alertType = 'success';
        this.alertColor = 'success';
        this.alertText = 'Venta registrada con éxito!';

        if (this.errors.length) {
          this.alertType = 'error';
          this.alertText = this.errors.join(', ');
        } else {
          this.alertType = 'success';
          this.alertColor = 'success';
          this.alertText = 'Venta registrada con éxito!';
        }
        this.loading = false;
      } catch (error) {
        console.error('Error al intentar crear orden', error);
      }
    },

    async processOrderStatus(orderStatus) {
      // console.log('Order status:', orderStatus);
      if (ValuesSalesIncome.includes(orderStatus)) await this.createDebt();
      if (!ValuesSalesDebt.includes(orderStatus)) await this.createIncome();
    },

    async createOrderDetail(orderId, ticketItem /*, order*/) {
      try {
        // console.log({ ticketItem });
        // console.log({ order });
        const orderDetail = {
          orden: orderId,
          producto: ticketItem.productId,
          unidades: ticketItem.quantity,
          unidad_medida: ticketItem.inventory.unidad_medida.id,
          autor: this.userData.id,
          institucion_educativa: this.institutionId,
          impuesto: this.calculateTax(ticketItem),
          precio_unitario: parseFloat(ticketItem.price).toFixed(2),
          sub_total: this.calculateSubtotal(ticketItem),
        };
        const response = await fetchPostOrderDetail(orderDetail);
        if (response) {
          this.alertText = 'Detalle de orden creada con éxito';
        } else {
          this.errors.push('No se pudo crear el detalle de orden');
        }
      } catch (error) {
        console.error('Error al intentar crear detalle de orden', error);
      }
    },
    async updateInventory(ticketItem) {
      try {
        const inventory = { ...ticketItem.inventory };
        const inventoryObj = {
          id: inventory.id,
          producto: ticketItem.productId,
          opcion_producto: inventory.opcion_producto.id,
          almacen: inventory.almacen.id,
          cantidad_disponible:
            inventory.cantidad_disponible - ticketItem.quantity,
          unidad_medida: inventory.unidad_medida.id,
          comentarios: inventory.comentarios,
          fecha_ultima_actualizacion: new Date(),
          id_autor: this.userData.id,
          id_institucion_educativa: this.institutionId,
        };
        const response = await fetchPutInventory(inventoryObj);
        if (response) {
          this.alertText = 'Inventario actualizado con exito';
        } else {
          this.errors.push('No se pudo actualizar inventario');
        }
      } catch (error) {
        console.error('Error al intentar crear inventario');
      }
    },
    async createInventoryMovement(ticketItem) {
      try {
        const movementType = await this.getOrCreateMovementType(
          BASE_INVENTORY_MOVEMENTS.MOVEMENT_TYPE,
          this.userData.id
        );
        const movementReason = await this.getOrCreateMovementReason(
          BASE_INVENTORY_MOVEMENTS.MOVEMENT_REASON,
          this.userData.id
        );

        const inventory = { ...ticketItem.inventory };

        const initialAmount = parseFloat(inventory.cantidad_disponible);
        const amountOfMovement = ticketItem.quantity;
        const finalAmount = initialAmount - amountOfMovement;
        const unitPrice = parseFloat(ticketItem.price);
        const valueOfMovement = unitPrice * amountOfMovement;

        const movementObj = {
          id_producto: ticketItem.productId,
          id_tipo_movimiento: movementType.id,
          id_motivo_movimiento: movementReason.id,
          id_almacen: inventory.almacen.id,
          inventario_inicial: initialAmount,
          cantidad_movimiento: amountOfMovement,
          inventario_final: finalAmount,
          precio_unitario: unitPrice,
          valor_movimiento: valueOfMovement,
          id_moneda: this.mxCoin.id,
          id_autor: this.userData.id,
          id_institucion_educativa: this.institutionId,
        };

        const response = await fetchPostMovementInventory(movementObj);
        if (response) {
          this.alertText = 'Movimiento de inventario creado con éxito';
        } else {
          throw new Error('No se pudo crear el movimiento de inventario.');
        }
      } catch (error) {
        console.error(
          'Error al intentar crear el movimiento de inventario:',
          error
        );
        throw new Error('Error al intentar crear el movimiento de inventario');
      }
    },
    async createAccountMovement() {
      const movement = this.createAccountTransaction(
        this.userData.id,
        this.accountId,
        this.ticketTotal.toFixed(2),
        MOVEMENT_TYPES_ACCOUNT.INCREASE
      );

      if (movement) {
        this.alertText = 'Movimiento de inventario creado con éxito';
      } else {
        throw new Error('No se pudo crear el movimiento de inventario.');
      }
    },
    async createIncome() {
      try {
        const incomeCategories = await this.getOrCreateIncomeCategory(
          BASE_CREATE_INCOME.VENTA,
          this.userData.id,
          this.institutionId
        );
        const incomeStatus = await this.getOrCreateIncomeStatus(
          BASE_CREATE_INCOME.PAGADO,
          this.userData.id,
          this.institutionId
        );
        const penalties = await this.getOrCreatePenalties(
          BASE_CREATE_INCOME.PENALIZACION,
          this.userData.id,
          this.institutionId
        );

        const incomeObj = {
          alumno: this.customerSelected.id,
          adeudo: [],
          categoria: incomeCategories.id,
          sub_total: this.ticketSubTotalPrice,
          total_impuestos: this.ticketSubTotalTaxes.toFixed(2),
          costo_envio: 0.0,
          total_descuento: 0.0,
          total_penalizaciones: 0.0,
          total_ingreso: this.ticketTotal.toFixed(2),
          descuento_aplicado: false,
          penalizacion_aplicada: false,
          penalizacion: penalties.id,
          forma_de_pago: this.paymentMethodId,
          moneda: this.mxCoin.id,
          cuenta_destino: this.accountId,
          estatus: incomeStatus.id,
          comprobantes: [],
          institucion_educativa: this.institutionId,
          autor: this.userData.id,
        };
        const response = await fetchPostIncome(incomeObj);
        if (response) {
          this.alertText = 'Ingreso creado con éxito';
        } else {
          this.errors.push(response.error);
        }
      } catch (error) {
        console.error('Error al intentar crear ingreso', error);
      }
    },
    async createDebt() {
      try {
        const debtStatus = await this.getOrCreateDebtStatus(
          BASE_CREATE_DEBT.DEBIT_ESTATUS,
          this.userData.id,
          this.institutionId
        );
        const promptPayDiscounts = await this.getOrCreatePromptPayDiscounts(
          BASE_CREATE_DEBT.DESCUENTO,
          this.userData.id,
          this.institutionId
        );
        const penalties = await this.getOrCreatePenalties(
          BASE_CREATE_DEBT.PENALIZACION,
          this.userData.id,
          this.institutionId
        );

        const today = new Date();

        const currentDay = today.getDate();
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();
        const expirationDate = new Date(
          currentYear,
          currentMonth,
          currentDay + 10
        );

        const expirationDay = expirationDate.getDate();
        const expirationMonth = expirationDate.getMonth();
        const expirationYear = expirationDate.getFullYear();
        const promptPayDate = new Date(
          expirationYear,
          expirationMonth,
          expirationDay - 5
        );

        const expirationDateStr = expirationDate.toISOString().substring(0, 10);
        const promptPayDateStr = promptPayDate.toISOString().substring(0, 10);

        const debtObj = {
          nombre_adeudo: 'Venta',
          alumno: this.customerSelected.id,
          comentarios: 'Venta',
          sub_total: this.ticketSubTotalPrice,
          total_impuestos: this.ticketSubTotalTaxes.toFixed(2),
          total_descuento: 0.0,
          total_adeudo: this.ticketTotal.toFixed(2),
          descuento_aplicado: false,
          moneda: this.mxCoin.id,
          estatus: debtStatus.id,
          expirado: false,
          fecha_vencimiento: expirationDateStr,
          fecha_pronto_pago: promptPayDateStr,
          penalizacion: penalties.id,
          descuento_pronto_pago: promptPayDiscounts.id,
          institucion_educativa: this.institutionId,
          autor: this.userData.id,
        };
        const response = await fetchPostDebit(debtObj);
        if (response) {
          this.alertText = 'Adeudo creado con éxito';
        } else {
          this.errors.push(response.error);
        }
      } catch (error) {
        console.error('Error al intentar crear adeudo', error);
      }
    },
    filterIt(arr, searchKey) {
      return arr.filter((item) =>
        item.producto.nombre_producto
          .toLowerCase()
          .includes(searchKey.toLowerCase())
      );
    },
    calculateRest() {
      // console.log('minuendo: ', this.minuend);
      if (this.minuend === null || this.minuend === 0 || this.minuend === '') {
        this.rest = null;
      } else {
        const result = this.minuend - this.ticketTotal;
        this.rest = parseFloat(result.toFixed(2));
      }
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
    cancel() {
      this.goTo('Ordenes');
    },
    cleanOrderSession() {
      this.cancelSessionOrder();
      this.orderStatusId = null;
      this.paymentMethodId = null;
      this.accountId = null;
      this.minuend = 0;
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.cleanOrderSession();
        this.goTo('Ordenes');
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.closeAlert();
      this.cleanOrderSession();
    },
    today() {
      const currentDate = new Date();
      return `${currentDate.toLocaleDateString(
        'es-MX'
      )} ${currentDate.toLocaleTimeString('es-MX')}`;
    },
    calculateTax(ticketItem) {
      const { price, quantity, tax } = ticketItem;
      const parsedPrice = parseFloat(price);
      const parseTax = parseFloat(tax);
      const normalizedTax = parseTax > 1 ? parseTax / 100 : parseTax;
      const totalTax = parsedPrice * normalizedTax * quantity;
      return totalTax.toFixed(2);
    },
    calculateSubtotal(ticketItem) {
      const { price, quantity } = ticketItem;
      const parsedPrice = parseFloat(price);
      const subtotal = parsedPrice * quantity;
      return subtotal.toFixed(2);
    },
  },
};
