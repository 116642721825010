<template>
  <v-card>
    <v-card-text>
      <v-text-field
        v-model="innerValue"
        dense
        outlined
        placeholder="Buscar producto..."
        v-bind="$attrs"
        v-on="$listeners"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: null
    },
  },
  data() {
    return {
      innerValue: null
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal;
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
}
</script>

<style>

</style>