<template>
  <v-row class="product-container overflow-y-auto mr-1">
    <slot></slot>
  </v-row>
</template>

<script>
export default {

}
</script>

<style>
.product-container {
  max-height: 600px;
}
</style>